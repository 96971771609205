/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    span: "span",
    h2: "h2",
    h3: "h3",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "What comes to your mind first when you want to book a taxi ? If you are thinking about ", React.createElement(_components.a, {
    href: "https://www.uber.com/in/en/",
    target: "_blank",
    rel: "nofollow"
  }, "Uber"), ", then you are right. As per ", React.createElement(_components.a, {
    href: "https://www.statista.com/statistics/946298/uber-ridership-worldwide/",
    target: "_blank",
    rel: "nofollow"
  }, "statista"), ", Uber has completed approximately 1.7 billion trips in the second quarter of 2020."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 56.49999999999999%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAeVbE9xGQ//EABgQAAIDAAAAAAAAAAAAAAAAAAIDAAEg/9oACAEBAAEFAo5QhWP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAABBQAAAAAAAAAAAAAAAAARAAECEiD/2gAIAQEABj8CTVkTn//EABgQAQEAAwAAAAAAAAAAAAAAAAEAIGGh/9oACAEBAAE/ISd0XWycP//aAAwDAQACAAMAAAAQ8y//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8Qsa//xAAZEAEAAwEBAAAAAAAAAAAAAAABABEhMUH/2gAIAQEAAT8QIET6lI1irw8gp2Ec4EW2f//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"streamline-your-taxi-business-main\"\n        title=\"\"\n        data-src=\"/static/9e35ce0cf2489b9ef138f753a95160a6/a24e6/streamline-your-taxi-business-main.jpg\"\n        data-srcset=\"/static/9e35ce0cf2489b9ef138f753a95160a6/a6b4f/streamline-your-taxi-business-main.jpg 200w,\n/static/9e35ce0cf2489b9ef138f753a95160a6/a6407/streamline-your-taxi-business-main.jpg 400w,\n/static/9e35ce0cf2489b9ef138f753a95160a6/a24e6/streamline-your-taxi-business-main.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "A few years ago, no one would have imagined that the emergence of taxi-hailing apps will drastically change the scenario of taxi business markets. As per ", React.createElement(_components.a, {
    href: "https://www.statista.com/forecasts/1124690/number-of-users-in-the-ride-hailing-and-taxi-market-worldwide",
    target: "_blank",
    rel: "nofollow"
  }, "statista"), ", the revenue in the ", React.createElement(_components.a, {
    href: "/blog/ride-hailing-market-in-middle-east/"
  }, "ride-hailing markets"), " will be $1,588.2 billion US dollar (approx.) in 2024. From this, we can say that the taxi-hailing industry is going to witness an upheaval. That is why you need to create a robust app for your taxi business."), "\n", React.createElement(_components.p, null, "Apart from giving a rich taxi-booking experience for your customers, a robust ", React.createElement(_components.a, {
    href: "/taxi-dispatch-software/"
  }, "taxi dispatch solution"), " can also reduce the time of your administrative tasks. You can efficiently employ some tools and resources to streamline your business."), "\n", React.createElement(_components.p, null, "Here are some software solutions which you can consider to manage your business :"), "\n", React.createElement(_components.h2, {
    id: "which-software-will-help-you-in-managing-your-business"
  }, "Which software will help you in managing your business?"), "\n", React.createElement(_components.h3, null, "HRMS Cloud"), "\n", React.createElement(_components.p, null, "Replacing the stereotypical excel sheets and paperwork with a robust HRMS cloud is a big deal for startups. Finding the best HRMS solution and customizing it as per your business needs is a complicated task."), "\n", React.createElement(_components.p, null, "An effective HRMS solution will facilitate your candidates in filling their form online. It will also manage the performance of your employees by aligning their interests with organizational goals."), "\n", React.createElement(_components.p, null, "It will save the time of the employee and the HR department by allowing them to log into their system from anywhere. It will also help you with tasks like payroll management and filing."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.bamboohr.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Bamboo Hr"), ", ", React.createElement(_components.a, {
    href: "https://www.kronos.in/",
    target: "_blank",
    rel: "nofollow"
  }, "Kronos"), ", ", React.createElement(_components.a, {
    href: "https://www.sage.com/en-us/products/sage-hrms/",
    target: "_blank",
    rel: "nofollow"
  }, "Sage"), ", ", React.createElement(_components.a, {
    href: "https://www.jazzhr.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Jazz Hr"), ", ", React.createElement(_components.a, {
    href: "https://www.paycom.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Paycom"), " are some of the HRMS solutions providers in the market."), "\n", React.createElement(_components.h3, null, "Finance solutions"), "\n", React.createElement(_components.p, null, "Whether you run a supermarket or a local store, keeping a grip on finances is crucial for success. The lack of effective money management and bookkeeping can lead your business towards losses."), "\n", React.createElement(_components.p, null, "Our robust ", React.createElement(_components.a, {
    href: "/blog/taxi-dispatch-system-for-traditional-taxi-services/"
  }, "taxi dispatch solution"), " will help you to analyze your daily expenses so that you can easily make a proper budget for controlling your business. It will also help you to create various financial reports like balance sheets, income statements, cash flow statements, analysis of accounting ratios, etc."), "\n", React.createElement(_components.p, null, "It also lets you decide your fares by considering different features like duration of the ride, distance traveled, vehicle type, number of passengers, luggage capacity, etc."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://zipbooks.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Zipbooks"), ", ", React.createElement(_components.a, {
    href: "https://www.accountingseed.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Accounting Seed"), ", ", React.createElement(_components.a, {
    href: "https://www.netsuite.com/portal/in/home.shtml",
    target: "_blank",
    rel: "nofollow"
  }, "Netsuite ERP"), ", ", React.createElement(_components.a, {
    href: "https://www.zoho.com/in/books/",
    target: "_blank",
    rel: "nofollow"
  }, "Zoho books"), " etc., are some widely used business finance solutions."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Read More: ", React.createElement(_components.a, {
    href: "/blog/save-time-and-money-using-taxi-dispatch-software/"
  }, " Save your time and money with taxi dispatch software")), "\n"), "\n", React.createElement(_components.h3, null, "Taxi Dispatch solution"), "\n", React.createElement(_components.p, null, "An ", React.createElement(_components.a, {
    href: "/taxi-dispatch-software/"
  }, "automated taxi dispatch solution"), " will reduce the booking timings by directly connecting your app users to the nearby drivers. In this way, the efficiency of your drivers will get increased as they will get more rides, which results in a higher commission for them. It also allows your customers to rate your services and write a review of them. They can also give ratings to their drivers."), "\n", React.createElement(_components.p, null, "An effective taxi dispatch solution will help you to prevent fraud by keeping a tab\nover your drivers. It also helps you in maintaining the safety of your customers by tracking all of your rides in real-time."), "\n", React.createElement(_components.p, null, "Heatmap is a graphical data visualization that will help you to understand the user’s actions on your webpage. It will also update you about the fluctuations in demand for your cabs. So that you can divert more rides towards that area. In this way, you can utilize your vehicles more efficiently."), "\n", React.createElement(_components.p, null, "The taxi dispatch solution will also help you in repairing the malfunctioning into your vehicles so that you can increase the capacity of your vehicles and provide a hassle-free service to your customer."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/blog/choosing-right-taxi-dispatch-software/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdoVYj//xAAYEAACAwAAAAAAAAAAAAAAAAAAAgEhMf/aAAgBAQABBQKcW1P/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAgEBPwGH/8QAFRABAQAAAAAAAAAAAAAAAAAAARD/2gAIAQEABj8Cb//EABgQAAIDAAAAAAAAAAAAAAAAAAABESEx/9oACAEBAAE/IavWwVG9IP/aAAwDAQACAAMAAAAQh9//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/EB//xAAYEAEBAQEBAAAAAAAAAAAAAAABEQBBYf/aAAgBAQABPxByACrMbNVe+4AQ3//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"streamline-your-taxi-business-cta1\"\n        title=\"\"\n        data-src=\"/static/acdaf47761936e56871b79f79e42df8b/a24e6/streamline-your-taxi-business-cta1.jpg\"\n        data-srcset=\"/static/acdaf47761936e56871b79f79e42df8b/a6b4f/streamline-your-taxi-business-cta1.jpg 200w,\n/static/acdaf47761936e56871b79f79e42df8b/a6407/streamline-your-taxi-business-cta1.jpg 400w,\n/static/acdaf47761936e56871b79f79e42df8b/a24e6/streamline-your-taxi-business-cta1.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "promoting-your-business-marketing-your-brand"
  }, "Promoting your business (Marketing your brand)"), "\n", React.createElement(_components.p, null, "We are living in an era of publicities and promotions. No matter how successful and profitable your business will be, it will not get enough attention from your customers if you fail to promote it properly. Here are some strategies to promote your taxi business efficiently :"), "\n", React.createElement(_components.h3, null, "Social media marketing"), "\n", React.createElement(_components.p, null, "Social media marketing strategy has been a cost-effective strategy to increase your brand awareness. Over the past years, many companies have created various social media marketing tools to track and manage their online campaigns automatically. They also provide you some useful insights to improve your virtual presence."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://optinmonster.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Optinmonster"), " is a powerful lead generation tool for your business. It has a drag-and-drop template for making attractive lightboxes, popups, and landing pages that capture leads. It also has a chatbot feature for directly connecting your customers with you."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://audiense.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Audiense"), " is another tool for segmenting your new visitors. It optimizes your customer engagement by providing you valuable insights about your product."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://tweepi.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Tweepi"), " helps you to increase your followers on twitter by looking for the appropriate users interested in your topic. You can later engage your followers by mentioning them in your tweets or by following them."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.socialbakers.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Socialbakers"), " helps you in making effective business decisions by utilizing the data of your followers. This tool will also provide you some valuable insights to monetize your social media campaigns, budgeting your finances, and retaining your customers effectively."), "\n", React.createElement(_components.p, null, "So these are some social media marketing tools that will help you to take your ", React.createElement(_components.a, {
    href: "/blog/automated-taxi-solution-for-your-taxi-business/"
  }, "taxi business"), " to the next level."), "\n", React.createElement(_components.h2, {
    id: "email-marketing"
  }, "Email marketing"), "\n", React.createElement(_components.p, null, "Email marketing is a digital strategy used for growing relationships with new and existing customers. Email marketing offers you several benefits like brand promotion, increased sales, enhanced audience engagement, etc. You can also promote your business by telling a story about it. You can market your business via an email by sending free downloads, white papers, free ebooks, lists of your new products and upcoming offers, etc., to your customers."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.constantcontact.com/in/home-page",
    target: "_blank",
    rel: "nofollow"
  }, "Constant contact"), ", ", React.createElement(_components.a, {
    href: "https://www.sendinblue.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Sendinblue"), ", ", React.createElement(_components.a, {
    href: "https://www.drip.com/",
    target: "_blank",
    rel: "nofollow"
  }, "drip"), ", ", React.createElement(_components.a, {
    href: "https://mailchimp.com/",
    target: "_blank",
    rel: "nofollow"
  }, "mailchimp"), ", ", React.createElement(_components.a, {
    href: "https://convertkit.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Convertkit"), ", etc. are the different email marketing tools for promoting the business."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Read More: ", React.createElement(_components.a, {
    href: "/blog/key-strategies-to-get-more-customers-taxi-business/"
  }, "Key Strategies for Boosting Your Taxi App")), "\n"), "\n", React.createElement(_components.h3, null, "SMS Marketing"), "\n", React.createElement(_components.p, null, "SMS marketing is a fantastic way of reaching out to your customers. You can promote your taxi business by sending SMS to your customers about your promotional schemes and offers. Give a human touch to your business by sending personalized messages to your raiders on their birthdays and anniversaries."), "\n", React.createElement(_components.p, null, "Share a link to the webpage of your upcoming ride-hailing offers to your customers if you want to share more information about it with them. You can also share the link of feedback or a survey form to your users for knowing what their likes and dislikes about their rides."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.servetel.in/",
    target: "_blank",
    rel: "nofollow"
  }, "Servetel"), ", ", React.createElement(_components.a, {
    href: "https://mtalkz.com/",
    target: "_blank",
    rel: "nofollow"
  }, "mtalkz"), ", ", React.createElement(_components.a, {
    href: "https://www.bitrix24.com/",
    target: "_blank",
    rel: "nofollow"
  }, "bitrix24"), ", ", React.createElement(_components.a, {
    href: "https://www.omnisend.com/landing-sms-email-marketing-software/?utm_medium=cpc&utm_campaign=sms&utm_source=capterra",
    target: "_blank",
    rel: "nofollow"
  }, "omnisend"), ", ", React.createElement(_components.a, {
    href: "https://www.routee.net/sms-marketing-campaigns-new-en/?utm_source=capterra&utm_medium=paid&utm_campaign=sms",
    target: "_blank",
    rel: "nofollow"
  }, "routee"), ", etc., are some effective tools for SMS marketing."), "\n", React.createElement(_components.h3, null, "PPC and Banner Ads"), "\n", React.createElement(_components.p, null, "PPC is the most agile way of getting more traffic to your website by reaching out to large numbers of potential customers. PPC allows you to buy visits on your website as you have to pay whenever your ad gets clicked."), "\n", React.createElement(_components.p, null, "Employ an efficient keyword planner for getting the most relevant keywords for your website. The keyword planner will help you to create more visible content."), "\n", React.createElement(_components.p, null, "You can also use ", React.createElement(_components.a, {
    href: "https://ads.google.com/intl/en_in/home/",
    target: "_blank",
    rel: "nofollow"
  }, "Google ads"), " for fastly growing the traffic over your website. It will also adjust to the current market trends by changing your PPC campaign as per the customer needs."), "\n", React.createElement(_components.h3, null, "Video Marketing"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/",
    target: "_blank",
    rel: "nofollow"
  }, "Youtube"), " is the best way to promote your taxi-hailing business effectively. It provides you with two kinds of ads: skippable ads and non-skippable ads."), "\n", React.createElement(_components.p, null, "Skippable ads are of short length, which the viewers can skip after some time. They are of two types: In-stream and In-display. In-stream ads are the ads shown at the starting of the video, while the In-display ads are the ones that you can display in the suggestions or related video section."), "\n", React.createElement(_components.p, null, "Non-skippable ads are the ads that the viewers have to watch compulsorily. These ads may also create a high disturbing rate sometimes."), "\n", React.createElement(_components.p, null, "To make an engaging Youtube video, you will first have to create a short introductory slide about your ", React.createElement(_components.a, {
    href: "/ride-hailing-software/"
  }, "ride-hailing business"), ". Creatively address your customers’ problems, which they are facing in their daily lives. Then show your customers how your taxi-hailing app can solve them efficiently. After a brief explanation of your product, you can tell your viewers to sign up for your app or visit your website to avail of your services. You can also add testimonials of your existing customers at the end of your advertisement to build loyalty for your business."), "\n", React.createElement(_components.p, null, "In this way, you can effectively leverage Youtube for growing your business."), "\n", React.createElement(_components.h2, {
    id: "wrapping-up"
  }, "Wrapping Up"), "\n", React.createElement(_components.p, null, "Finding and implementing the best software and platforms into your business can help you to reap more profits. It will also make your bookings convenient. That is why you need to think carefully before creating a taxi-dispatch solution for your business."), "\n", React.createElement(_components.p, null, "You can outsource app development, or you can hire dedicated software team to make a productive taxi dispatch solution for your business. I hope this blog has provided you with detailed information about the software and platforms to run your business smoothly. Keep following this space for more such insights."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/contact/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHSmFSA/wD/xAAXEAEBAQEAAAAAAAAAAAAAAAABAgAS/9oACAEBAAEFAoVqw5bd/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEBAAY/AjX/AP/EABgQAQADAQAAAAAAAAAAAAAAAAEAETEh/9oACAEBAAE/IbhWEU7M7AOE/9oADAMBAAIAAwAAABCED//EABYRAAMAAAAAAAAAAAAAAAAAAAABMf/aAAgBAwEBPxBUdP/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EGf/xAAbEAEAAgIDAAAAAAAAAAAAAAABAGERITFxkf/aAAgBAQABPxADXgCBjdStQ7jkPGf/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"streamline-your-taxi-business-cta3\"\n        title=\"\"\n        data-src=\"/static/7921aa9fd41a027c7ddbfa972ca4353e/a24e6/streamline-your-taxi-business-cta3.jpg\"\n        data-srcset=\"/static/7921aa9fd41a027c7ddbfa972ca4353e/a6b4f/streamline-your-taxi-business-cta3.jpg 200w,\n/static/7921aa9fd41a027c7ddbfa972ca4353e/a6407/streamline-your-taxi-business-cta3.jpg 400w,\n/static/7921aa9fd41a027c7ddbfa972ca4353e/a24e6/streamline-your-taxi-business-cta3.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
